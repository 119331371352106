/*.rcw-widget-container {
  height: auto !important;
  width: 370px;
}
.rcw-widget-container .rcw-conversation-container {
  width: auto !important;
  margin-bottom: 10px;
  margin-right: 100px;
}

@media screen and (max-width: 800px) {
  .rcw-widget-container {
    height: 100% !important;
    width: auto !important;
  }
  .rcw-widget-container .rcw-conversation-container {
    height: 100vh !important;
    width: 100vw !important;
    margin-bottom: 0;
    margin-right: 0;
  }
  .rcw-messages-container {
    height: 100%;
    max-height: none;
    width: 100vw;
  }
}
*/

body>iframe {
  pointer-events: none;
}

/* This is a temporary fix for CRM live chat widget for jamaica teritory
*/
#Microsoft_Omnichannel_LCWidget_Chat_Iframe_Window {
  pointer-events: auto;
}

/*.rcw-widget-container {
  height: 50px !important;
  height: 100vh; 
  margin: 0;
  max-width: none;
  width: 100%;
}
// margin-right: 0;
*/

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

pre {
  outline: 1px solid #ccc;
  padding: 5px;
  margin: 5px;
}

.string {
  color: green;
}

.number {
  color: darkorange;
}

.boolean {
  color: blue;
}

.null {
  color: magenta;
}

.key {
  color: red;
}